import { Suspense, useMemo, useState } from "react";
import { Fade, styled } from "@mui/material";
import { Link, Modal, ModalCloseButton, ModalContent, Typography } from "@kaltura/ds-react-components";
import { baseUrl, fetchKmsData, translate, WebcastHelper, WebcastStatus } from "@kaltura/mediaspace-shared-utils";
import { useButtonAnalytics, useCurrentTimeForAnimation } from "@kaltura/mediaspace-shared-hooks";
import { Box, useTheme } from "@kaltura/mediaspace-shared-styled";
import { KmsTypePresenter } from "@kaltura/mediaspace-shared-types";
import { ThemeProvider } from "@kaltura/ds-react-theme";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { ErrorBoundary } from "@kaltura/mediaspace-shared-ui";
import { UserCard, UserCardSkeleton, UserSeeProfileButton } from "@kaltura/mediaspace-shared-user";
import { EventList, ScheduledSession } from "../../event-list/EventList";

const baseClassName = "kms-ds-group-user-modal";
const paperClassName = baseClassName + "-paper";

export interface EventPresenterModalProps {
    open: boolean;
    onClose?: () => void;
    user: KmsTypePresenter;
}

export const EventPresenterModal = ({ open, onClose, user }: EventPresenterModalProps) => {
    const fetchSessions = useUserSessions(user.id);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleClose = () => {
        onClose?.();
        sendButtonAnalytics("User modal box - Close modal", ButtonClickAnalyticsType.CLOSE);
    };

    return (
        <StyledModal
            className={baseClassName}
            variant={"content"}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            classes={{ paper: paperClassName }}
            // Use "fade" transition instead of the default (zoom + fade)
            // because Truncate component in the contents doesn't work properly
            // when the CSS animation changes the size of the element
            TransitionComponent={Fade}
        >
            <ModalCloseButton variant={"pill"} onClick={handleClose} aria-label={translate("close")} />

            <ModalContent>
                <ErrorBoundary fallback={<UserModelCard user={user} />}>
                    <Suspense fallback={<UserCardSkeleton />}>
                        <EventPresenterModalContent user={user} fetchSessions={fetchSessions} />
                    </Suspense>
                </ErrorBoundary>
            </ModalContent>
        </StyledModal>
    );
};

const UserModelCard = ({ user }: Pick<EventPresenterModalProps, "user">) => (
    <UserCard
        user={user}
        size={"big"}
        showMoreElement={user.link && <UserSeeProfileButton href={user.link} />}
        showMore={true}
    />
);

export interface EventPresenterModalContentProps {
    user: KmsTypePresenter;
    fetchSessions: () => ScheduledSession[];
}

export const EventPresenterModalContent = ({ user, fetchSessions }: EventPresenterModalContentProps) => {
    const sessions = fetchSessions();

    // Refresh the component each second to display live status according to the current time
    useCurrentTimeForAnimation(undefined, 1000);

    // Map sessions by live statuses
    const sessionsByStatus: Record<WebcastStatus, ScheduledSession[]> = {
        [WebcastStatus.noScheduling]: [],
        [WebcastStatus.past]: [],
        [WebcastStatus.live]: [],
        [WebcastStatus.future]: [],
    };
    for (const session of sessions) {
        const { schedulingData } = session;
        if (schedulingData) {
            sessionsByStatus[WebcastHelper.getLiveStatus(schedulingData)].push(session);
        }
    }
    const { past, live, future } = sessionsByStatus;

    // Show live/future sessions if available, otherwise show past sessions
    let filteredSessions: ScheduledSession[];
    let label: string;
    if (live.length || future.length) {
        label = translate("Scheduled sessions");
        filteredSessions = [...live, ...future];
    }
    else {
        label = translate("Previous sessions");
        filteredSessions = past;
    }

    /*
     * Substitute the breakpoints for the modal's content, so that it will always render the mobile view
     * (because the modal's max width is 790px)
     */
    const theme = useTheme();
    const contentTheme = useMemo(() => {
        return {
            ...theme.kaltura,
            breakpoints: {
                ...theme.kaltura.breakpoints,
                /*
                 * Set breakpoints to extra large numbers for "md" and above,
                 * so that rules like `breakpoints.down("md")` will hit all devices.
                 * It will eventually lead to rendering the "sm" version of the components on the large devices.
                 */
                xl: 100000,
                lg: 100000,
                md: 100000,
            },
        };
    }, [theme]);

    const sendButtonAnalytics = useButtonAnalytics();

    return (
        <>
            <UserModelCard user={user} />

            {filteredSessions.length > 0 && (
                <StyledSessionsContainer>
                    <StyledSessionsLabel variant={"body2Highlight"}>{label}</StyledSessionsLabel>

                    <ThemeProvider overrides={contentTheme} scoped={true} injectFirst={false}>
                        <StyledSessionsList sessions={filteredSessions.slice(0, 2)} />
                    </ThemeProvider>

                    <StyledSessionsLink
                        href={user.link}
                        size={"large"}
                        onClick={() =>
                            sendButtonAnalytics("User modal box - See all sessions", ButtonClickAnalyticsType.LINK)
                        }
                    >
                        {translate("See all sessions")}
                    </StyledSessionsLink>
                </StyledSessionsContainer>
            )}
        </>
    );
};

/**
 * Lazy-load user's sessions from the backend.
 *
 * The user ID from the prop could change (when showing the modal for different users),
 * so the state stores a map of sessions by user ID.
 *
 * When the input user ID changes (see the useEffect),
 * the component checks if the map already contains the data for the requested user,
 * and fetches the data from the backend if it's not there yet.
 */
const useUserSessions = (userId: string) => {
    const [sessionsMap] = useState<Record<string, () => ScheduledSession[]>>({});

    /*
     * Start fetching user sessions if didn't start doing it yet.
     *
     * Note: no need to use React hooks to update the state:
     * - sessionsMap is an object, so it could be modified in place.
     * - Component already re-rendered if userId changed, so UI will update.
     * - We need to have the new data fetcher available immediately,
     *   not on the next render, so useEffect() is not an option.
     */
    sessionsMap[userId] =
        sessionsMap[userId] ?? fetchKmsData<ScheduledSession[]>(baseUrl + "/grouppage/index/sessions", { userId });

    return sessionsMap[userId];
};

const StyledModal = styled(Modal)({
    [`.${paperClassName}`]: {
        maxWidth: 790,
    },
});

const StyledSessionsContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
}));

const StyledSessionsLabel = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone1,
}));

const StyledSessionsList = styled(EventList)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const StyledSessionsLink = styled(Link)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));
