import { FC, ReactNode, useEffect, useState } from "react";
import { Info24Icon, StarFull24Icon, StarHollow24Icon } from "@kaltura/ds-react-icons";
import { kmsConnect, WrappedProps } from "@kaltura/mediaspace-shared-kms-connect";
import { AddToWatchlistHelper, baseUrl, translate, useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { WebcastEntry } from "@kaltura/mediaspace-shared-types";
import { useTheme } from "@kaltura/mediaspace-shared-styled";
import { ActionButton, ActionIconButton, ActionItem, Left, Right } from "./StyledComponents";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { Tooltip } from "@kaltura/ds-react-components";

export interface ActionSectionProps extends WrappedProps {
    className?: string;
    data: WebcastEntry /** entry to show */;
    callToActionButton?: ReactNode;
    onShowMore?: () => void;
    inWatchlist?: boolean;
    canAddToWatchList?: boolean;
    onToggleWatchList?: (id: string, add: boolean) => void;
}

/**
 * Actions component for Event List Item
 */
const ActionSection: FC<ActionSectionProps> = ({
    className,
    data,
    callToActionButton,
    onShowMore,
    getFromKms,
    inWatchlist,
    canAddToWatchList,
    onToggleWatchList,
}) => {
    const [watchlistText, setWatchlistText] = useState(translate("Added To Watch List"));

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

    const AddToWatchlistText = translate("Add To Watch List");
    const AddedToWatchlistText = translate("Added To Watch List");
    const RemoveFromWatchlistText = translate("Remove From Watch List");

    const config = useConfig();

    const sendButtonAnalytics = useButtonAnalytics();

    const [inWatchlistState, setInWatchlistState] = useState<boolean>(
        inWatchlist !== undefined ? inWatchlist : AddToWatchlistHelper.getInWatchlist(data.id)
    );

    // handle events from entry page components
    useEffect(() => {
        window.addEventListener(
            "kms-added-to-watchlist",
            () => {
                setInWatchlistState(true);
                setWatchlistText(AddedToWatchlistText);
                AddToWatchlistHelper.addToWatchlist(data.id);

                onToggleWatchList?.(data.id, true);
            }
        );
        window.addEventListener(
            "kms-removed-from-watchlist",
            () => {
                setInWatchlistState(false);
                setWatchlistText(AddToWatchlistText);
                AddToWatchlistHelper.removeFromWatchlist(data.id);

                onToggleWatchList?.(data.id, false);
            }
        );
    }, [data.id, AddedToWatchlistText, AddToWatchlistText, onToggleWatchList]);

    const watchlistbuttonHoverHandler = () => {
        const text = !inWatchlistState ? AddedToWatchlistText : RemoveFromWatchlistText;
        setWatchlistText(text);
    };

    const watchlistButtonMouseLeaveHandler = () => {
        setWatchlistText(AddedToWatchlistText);
    };

    useEffect(() => {
        setInWatchlistState(inWatchlist !== undefined ? inWatchlist : AddToWatchlistHelper.getInWatchlist(data.id));
    }, [inWatchlist, data]);

    const handleClick = () => {
        const params = { id: data.id };
        if (!config.application.isLoggedIn) {
            window.location.href = `${baseUrl}/user/login?ref=${encodeURIComponent(window.location.href)}/`;
            return;
        }

        // keep initial state for the ajax callback, because it's being changed immediately on the ui.
        const inWatchlistCurrent = inWatchlistState;

        // update button state immediately for better ui experience
        toggleButtonState();

        getFromKms(
            params,
            (response) => {
                !inWatchlistCurrent
                    ? AddToWatchlistHelper.addToWatchlist(data.id)
                    : AddToWatchlistHelper.removeFromWatchlist(data.id);
                // failed - revert button state
                if (response["result"] !== true) {
                    toggleButtonState();
                }
            },
            !inWatchlistCurrent
                ? `${baseUrl}/watchlist/index/add/id/${data.id}`
                : `${baseUrl}/watchlist/index/delete/id/${data.id}`,
            true
        );

        const actionName = inWatchlistCurrent
            ? "Session card - Remove from Watchlist"
            : "Session card - Add to Watchlist";
        sendButtonAnalytics(actionName, ButtonClickAnalyticsType.TOGGLE);

        onToggleWatchList?.(data.id, !inWatchlistCurrent);
    };

    // toggle button text and start color
    const toggleButtonState = () => {
        setInWatchlistState((prevState) => {
            const text = prevState ? AddToWatchlistText : AddedToWatchlistText;
            setWatchlistText(text);
            // sync with entry Actions dropdown button - trigger event
            const event = new CustomEvent("kmsreact-watchlist-changed", { detail: prevState });
            document.body.dispatchEvent(event);
            return !prevState;
        });
    };

    // Check if the entry can be added to watchlist. By prop if we're in entry page, or by entry prop if
    // the entry is part of a session list.
    const showAddToWatchListBtn = canAddToWatchList || data.canAddToWatchList;

    const watchListTooltipTitle = !inWatchlistState
        ? translate("Add %1 to Watch List", [data.name])
        : translate("Remove %1 from Watch List", [data.name]);

    return (
        <ActionItem className={className}>
            <Left>
                {callToActionButton && callToActionButton}
                {showAddToWatchListBtn && isLargeScreen && (
                    <ActionButton
                        variant={"borderless"}
                        size={"large"}
                        onClick={handleClick}
                        onMouseEnter={watchlistbuttonHoverHandler}
                        onFocus={watchlistbuttonHoverHandler}
                        onMouseLeave={watchlistButtonMouseLeaveHandler}
                        onBlur={watchlistButtonMouseLeaveHandler}
                        className={`kms-ds-event-list-item-button-watchlist`}
                        startIcon={!inWatchlistState ? <StarHollow24Icon /> : <StarFull24Icon />}
                        color={"secondary"}
                    >
                        {!inWatchlistState ? AddToWatchlistText : watchlistText}
                    </ActionButton>
                )}
                {showAddToWatchListBtn && !isLargeScreen && (
                    <Tooltip title={watchListTooltipTitle} placement={"bottom-start"}>
                        <ActionIconButton
                            variant={"borderless"}
                            onClick={handleClick}
                            className={`kms-ds-event-list-item-button-watchlist`}
                            size={"large"}
                            color={"secondary"}
                        >
                            {!inWatchlistState ? <StarHollow24Icon /> : <StarFull24Icon />}
                        </ActionIconButton>
                    </Tooltip>
                )}
            </Left>
            {!!onShowMore && (
                <Right>
                    {isLargeScreen && (
                        <ActionButton
                            variant={"borderless"}
                            onClick={() => onShowMore()}
                            className={`kms-ds-event-list-item-button-more`}
                            startIcon={<Info24Icon />}
                            size={"large"}
                            aria-label={translate("More info - %1", [data.name])}
                            color={"secondary"}
                        >
                            {translate("More info")}
                        </ActionButton>
                    )}
                    {!isLargeScreen && (
                        <Tooltip title={translate("More info - %1", [data.name])} placement={"bottom-start"}>
                            <ActionIconButton
                                variant={"borderless"}
                                onClick={() => onShowMore()}
                                className={`kms-ds-event-list-item-button-more`}
                                size={"large"}
                                aria-label={translate("More info - %1", [data.name])}
                                color={"secondary"}
                            >
                                <Info24Icon />
                            </ActionIconButton>
                        </Tooltip>
                    )}
                </Right>
            )}
        </ActionItem>
    );
};

export default kmsConnect<ActionSectionProps>(ActionSection);
