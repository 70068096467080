// noinspection PointlessArithmeticExpressionJS

import { WebcastEntry } from "@kaltura/mediaspace-shared-types";
import { KalturaEntryType } from "kaltura-typescript-client/api/types/KalturaEntryType";
import { generateLiveEntry } from "@kaltura/mediaspace-shared-data-stubs";

const now = Date.now();
const liveEntries = [
    generateLiveEntry("441"),
    generateLiveEntry("442"),
    generateLiveEntry("443"),
    generateLiveEntry("444"),
];

const entries: WebcastEntry[] = [
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "1",
        name: "single Live now 1",
        description:
            "truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        tags: "breaking-out talk,producing communities,tag3,tag4,tag5,tag6",
        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        stats: {
            plays: 1,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "1",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "2",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 1 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "superLongSingleStringName",
        name: "LoremipsumdolorsitametconsecteturadipiscingelitSeddoeiusmodtemporincididuntutlaboreetdoloremagnaaliquaUtveniamquisnostrudexercitationullamcolaborisnisiutaliquipexeacommodoconsequatDuisautereiruredolorinreprehenderitinvoluptatevelitessecillumdoloreeufugiatnullapariaturExcepteursintoccaecatcupidatatnonproident",
        description:
            "truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate truncate",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        tags: "breaking-out talk,producing communities,tag3,tag4,tag5,tag6",
        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        stats: {
            plays: 1,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "1",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "2",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 1 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "2",
        name: "Upcoming session - with join buffer",
        description:
            "This card's CTA should update from 'Add to Calendar' to 'Join now' after 5 seconds, when the buffer (15 minutes before event start time) is reached. See SessionCardJoinNowIndication config in Widgets module for more info. Applies to the EventListItem component.",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag2",
        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 15 * 60 + 5,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 2 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "3",
        name: "webcast with qna 3",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        tags: "tag3",
        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "4",
        name: "Past event 4",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag4",
        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 - 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 - 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "5",
        name: "Future event 5",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/0_zx2hc2jo/version/100002",
        tags: "tag5",
        createdAt: 1480492931,
        lastBroadcast: 1480494142,
        duration: 782,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "3",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 3 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "6",
        name: "single Live now 6",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        tags: "tag6",
        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        stats: {
            plays: 1,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "4",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "5",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "7",
        name: "Live Now! 7",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag7",
        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "8",
        name: "webcast with qna 8",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        tags: "tag6",
        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "9",
        name: "Past event 9",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag5",
        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 - 4 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 - 3 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "10",
        name: "Future event 10",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/0_zx2hc2jo/version/100002",
        tags: "tag4",
        createdAt: 1480492931,
        lastBroadcast: 1480494142,
        duration: 782,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "6",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 3 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "11",
        name: "single Live now 11",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        tags: "tag1,tag2,tag3",
        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        stats: {
            plays: 1,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "7",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "8",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "12",
        name: "Live Now! 12",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag3",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "13",
        name: "webcast with qna 13",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        tags: "tag2",

        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "14",
        name: "Past event 14",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag3",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 - 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 - 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "20",
        name: "Future event 15",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/0_zx2hc2jo/version/100002",
        tags: "tag4",

        createdAt: 1480492931,
        lastBroadcast: 1480494142,
        duration: 782,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "9",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 3 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "15",
        name: "single Live now 16",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        tags: "tag1",

        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        stats: {
            plays: 1,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "10",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "11",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "16",
        name: "Live Now! 17",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag3",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "17",
        name: "webcast with qna 18",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        tags: "tag2",

        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "18",
        name: "Past event 19",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag3",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 - 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 - 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "19",
        name: "Future event 20",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/0_zx2hc2jo/version/100002",
        tags: "tag4",

        createdAt: 1480492931,
        lastBroadcast: 1480494142,
        duration: 782,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "12",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 3 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "21",
        name: "single Live now 21",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        tags: "tag1,tag2,tag3,tag4,tag5,tag6",

        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        stats: {
            plays: 1,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "1",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "2",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "22",
        name: "Live Now! 22",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag2",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "23",
        name: "webcast with qna 23",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        tags: "tag3",

        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "24",
        name: "Past event 24",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag4",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 - 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 - 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "25",
        name: "Future event 25",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/0_zx2hc2jo/version/100002",
        tags: "tag5",

        createdAt: 1480492931,
        lastBroadcast: 1480494142,
        duration: 782,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "3",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 3 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "26",
        name: "single Live now 26",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        tags: "tag6",

        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        stats: {
            plays: 1,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "4",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "5",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "27",
        name: "Live Now! 27",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag7",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "28",
        name: "webcast with qna 28",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        tags: "tag6",

        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "29",
        name: "Past event 29",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag5",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 - 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 - 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "30",
        name: "Future event 30",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/0_zx2hc2jo/version/100002",
        tags: "tag4",

        createdAt: 1480492931,
        lastBroadcast: 1480494142,
        duration: 782,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "6",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 3 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "31",
        name: "single Live now 31",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        tags: "tag1,tag2,tag3",

        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        stats: {
            plays: 1,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "7",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "8",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "32",
        name: "Live Now! 32",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag3",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "33",
        name: "webcast with qna 33",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        tags: "tag2",

        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "34",
        name: "Past event 34",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag3",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 - 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 - 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "35",
        name: "Future event 35",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/0_zx2hc2jo/version/100002",
        tags: "tag4",

        createdAt: 1480492931,
        lastBroadcast: 1480494142,
        duration: 782,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "9",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 3 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "36",
        name: "single Live now 36",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        tags: "tag1",

        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        stats: {
            plays: 1,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [
            {
                id: "10",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "11",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "37",
        name: "Live Now! 37",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag3",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "38",
        name: "webcast with qna 38",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        tags: "tag2",

        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        canAddToWatchList: true,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: now / 1000 + 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "39",
        name: "Past event 39",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        tags: "tag3",

        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now / 1000 - 2 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 - 1 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        types: [],
        id: "40",
        name: "Future event 40",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/0_zx2hc2jo/version/100002",
        tags: "tag4",

        createdAt: 1480492931,
        lastBroadcast: 1480494142,
        duration: 782,
        stats: {
            plays: 0,
            votes: 0,
            comments: 0,
        },
        presenters: [
            {
                id: "12",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: now / 1000 + 4 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now / 1000 + 5 * 24 * 60 * 60,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
];

export const sessions = entries.concat(liveEntries);
