import { styled } from "@mui/material";
import { TruncatedLine, StatusLive } from "@kaltura/mediaspace-shared-ui";
import { Typography } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";
import { Skeleton } from "@kaltura/mediaspace-shared-styled";

export interface UserNameProps {
    className?: string;
    name: string;
    size?: "big" | "small";
    isLive?: boolean;
}

export const UserName = ({ className, name, size = "small", isLive }: UserNameProps) => {
    return (
        <StyledName variant={size === "big" ? "h2" : "h4"} component={"div"} className={className}>
            <TruncatedLine>{HtmlReactParser(name)}</TruncatedLine>
            {isLive && <StatusLive>{translate("Live")}</StatusLive>}
        </StyledName>
    );
};

const StyledName = styled(Typography)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    color: theme.kaltura.palette.tone1,
    margin: 0,
}));

export const UserNameSkeleton = styled(Skeleton)(({ theme }) => ({
    height: theme.spacing(3),
    borderRadius: theme.kaltura.shape.roundness1,
}));
