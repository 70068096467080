import { Button } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import styled from "@emotion/styled";

export interface UserSeeProfileButtonProps {
    href: string;
}

export const UserSeeProfileButton = ({ href }: UserSeeProfileButtonProps) => {
    const sendButtonAnalytics = useButtonAnalytics();

    return (
        <StyledButton
            variant={"pill"}
            size={"large"}
            color={"secondary"}
            component={"a"}
            href={href}
            onClick={() => sendButtonAnalytics("User modal box - Link to profile page", ButtonClickAnalyticsType.LINK)}
            className={"kms-ds-grouppage-see-profile-button"}
        >
            {translate("See profile page")}
        </StyledButton>
    );
};

const StyledButton = styled(Button<"a">)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));
